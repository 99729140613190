import common from "@/common";

export default [
  {
    name: 'companyCode',
    label: 'company.companyCode',
    type: 'text',
    rule: 'required|max: 5',
    edit_disable: true,
    description: 'company.code_editable'
  },
  {
    name: 'chineseName',
    label: 'company.chineseName',
    type: 'text',
    rule: 'required|max: 20',
  },
  {
    name: 'englishName',
    label: 'company.englishName',
    type: 'text',
    rule: 'required|max: 50'
  },
  {
    name: 'chineseAddress',
    label: 'company.chineseAddress',
    type: 'text',
    rule: 'required|max: 100'
  },
  {
    name: 'englishAddress',
    label: 'company.englishAddress',
    type: 'text',
    rule: 'required|max: 150'
  },
  {
    name: 'contactPhone',
    label: 'company.contactPhone',
    type: 'text',
    rule: 'required|max: 20'
  },
  {
    name: 'contactPerson',
    label: 'company.contactPerson',
    type: 'text',
    rule: 'required|max: 20'
  },
  {
    name: 'contractEndDay',
    label: 'company.contractEndDay',
    type: 'date',
    rule: 'required'
  },
  {
    name: 'isSingleFacility',
    label: 'company.isSingleFacility',
    type: 'radio',
    rule: 'required',
    options: [
      {text: common.getI18n('company.single_facility'), value: true},
      {text: common.getI18n('company.multi_facility'), value: false},
    ],
  },

]
